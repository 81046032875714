import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Firefox 72: `}<a parentName="p" {...{
            "href": "https://www.mozilla.org/en-US/firefox/72.0/releasenotes/"
          }}>{`https://www.mozilla.org/en-US/firefox/72.0/releasenotes/`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Blockerar "fingerprinting scripts" som standard`}</li>
          <li parentName="ul">{`Trevligare blockering av Notis-förfrågningar`}</li>
          <li parentName="ul">{`Picture-in-picture für alles!`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Darktable 3.0: `}<a parentName="p" {...{
            "href": "https://www.darktable.org/2019/12/darktable-30/"
          }}>{`https://www.darktable.org/2019/12/darktable-30/`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Nytt (css-themable) UI`}</li>
          <li parentName="ul">{`Bättre Noise Reduction`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`KDE flörtar med Windows 7 användare: `}<a parentName="p" {...{
            "href": "https://dot.kde.org/2020/01/08/plasma-safe-haven-windows-7-refugees"
          }}>{`https://dot.kde.org/2020/01/08/plasma-safe-haven-windows-7-refugees`}</a></p>
      </li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Kinesisk spyware eller fake news? - `}<a parentName="li" {...{
          "href": "https://www.reddit.com/r/Android/comments/ektg8u/chinese_spyware_preinstalled_on_all_samsung/"
        }}>{`https://www.reddit.com/r/Android/comments/ektg8u/chinese_spyware_preinstalled_on_all_samsung/`}</a></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Node-RED: `}<a parentName="li" {...{
          "href": "https://nodered.org/"
        }}>{`https://nodered.org/`}</a></li>
      <li parentName="ul">{`fediverse.space: `}<a parentName="li" {...{
          "href": "https://www.fediverse.space/"
        }}>{`https://www.fediverse.space/`}</a></li>
      <li parentName="ul">{`DeGoogle yourself:`}<ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/tycrek/degoogle"
            }}>{`https://github.com/tycrek/degoogle`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://restoreprivacy.com/google-alternatives/"
            }}>{`https://restoreprivacy.com/google-alternatives/`}</a></li>
        </ul></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Alex diskuterar OS till PinePhone: `}<a parentName="p" {...{
            "href": "https://wiki.pine64.org/index.php/PinePhone"
          }}>{`https://wiki.pine64.org/index.php/PinePhone`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Ubuntu Touch`}</li>
          <li parentName="ul">{`PostmarketOS`}</li>
          <li parentName="ul">{`Sailfish OS`}</li>
          <li parentName="ul">{`Manjaro?`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Seb benar ut vad en distro är och hur dessa (oftast) är uppbyggda`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Kerneln (Kärnan)`}</li>
          <li parentName="ul">{`Pakethanterare`}</li>
          <li parentName="ul">{`Skal / Skrivbordsmiljö`}</li>
          <li parentName="ul">{`Distributioner`}</li>
        </ul>
      </li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Cursed Intro - Dark Dramatic Epic Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`License for all: CC BY (`}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a>{`)`}</li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      